<template data-app>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="card">
      <div class="filters my-2" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          Categorias<v-btn
            color="#003a56"
            dark
            class="mb-2"
            style="float: right"
            @click.prevent="handleCreate()"
            >Crear
          </v-btn>
        </h3>
        <label>Filtrar por:</label>

        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="my-2"
          ><v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <multiselect
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                placeholder="Tipo"
                v-model="filters.type"
                :options="typeOptions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>

            <v-btn
              color="#003a56"
              style="min-width: auto; position: absolute; right: 50px; height: 25px"
              dark
              class="px-1 py-1"
              v-if="filterActivated"
              @click.prevent="
                filterActivated = false
                cleanFilters()
                getCategories()
              "
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="#003a56"
                  style="min-width: auto; position: absolute; right: 10px; height: 25px"
                  dark
                  class="px-1 py-1"
                  @click.prevent="
                    filterActivated = true
                    getCategories()
                  "
                  ><v-icon dark> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>Filtrar</span>
            </v-tooltip>
          </v-row>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-4'"
        :sortable="true"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="true"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" :height="'75%'" :width="'80%'" :adaptive="true">
      <h3 class="text-center" v-if="flow == 'create'">Crear Categoria</h3>
      <h3 class="text-center" v-if="flow == 'edit'">Editar Categoria</h3>
      <div class="card" style="padding: 2rem; margin: 20px">
        <h5 style="margin-bottom: 20px">Datos generales</h5>
        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-row>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                :rules="[(v) => !!v || 'Requerido']"
                :items="types"
                :item-text="'name'"
                :item-value="'code'"
                v-model="type"
                label="Tipo de Categoria"
              ></v-select
            ></v-col>

            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="[rules.required, rules.maxTitle]"
                label="Nombre"
                v-model="name"
                counter
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-row>
                <a
                  style="width: 40%"
                  :href="item.image.route"
                  v-if="flow == 'edit' && item.image && item.image.route"
                  target="_blank"
                  rel="noopener"
                  ><v-img :src="item.image.route"></v-img
                ></a>

                <v-file-input
                  style="width: 60%"
                  v-model="image"
                  chips
                  clearable
                  clear-icon="mdi-close-circle"
                  ref="file"
                  show-size
                  accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                  :label="flow == 'create' ? 'Imagen' : 'Click para cambiar imagen'"
                ></v-file-input
              ></v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>

      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            v-if="flow == 'create'"
            :disabled="!valid"
            color="#003a56"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            v-if="flow == 'edit'"
            :disabled="!valid"
            color="#003a56"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn style="margin: auto; margin-top: 10px; color: #fff" color="#003a56" @click="reset">
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            color="#003a56"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'Categories',
    components: {
      SimpleList,
      Multiselect,
    },
    data() {
      return {
        config: {
          headers: {},
        },
        loading: false,
        headers: [
          { text: 'Tipo', value: 'typeText' },
          { text: 'Nombre', value: 'name' },

          { text: 'Acciones', value: 'actions', align: 'center', width: '60px' },
        ],
        types: [
          { code: '0', name: 'Empresas' },
          { code: '1', name: 'Blog' },
          { code: '2', name: 'Eventos' },
          { code: '3', name: 'Noticias' },
          { code: '4', name: 'Glosario de Términos' },
        ],

        items: [],
        users: [],
        companies: [],
        selectedCategories: [],
        selectedCountries: [],
        countries: [],
        categories: [],
        page: 1,
        pages: 1,
        item: {},
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
          { id: 5, name: 'Fecha de publicación', order: 'date', by: 'desc' },
        ],
        filters: {
          search: '',
          type: null,
        },
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activas' },
          { id: 0, name: 'Inactivas' },
        ],
        typeOptions: [
          { id: null, name: 'Todos' },
          { id: '0', name: 'Empresas' },
          { id: '1', name: 'Blog' },
          { id: '2', name: 'Eventos' },
          { id: '3', name: 'Noticias' },
          { id: '4', name: 'Glosario de Términos' },
        ],

        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        name: '',
        type: '',
        image: null,
        loadingText: 'Cargando registros',
        valid: true,

        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 140 || 'Debe poser menos de 140 caracteres',
          max: (v) => v.length <= 80 || 'Debe poser menos de 140 caracteres',
          maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
        },
      }
    },
    created() {
      this.getCategories()
    },
    computed: {},
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getCategories()
      },
      fullName({ name, lastname, email }) {
        if (name && name != 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      cleanFilters() {
        this.filters = {
          search: '',
          type: null,
          author: null,
          outstanding: null,
          date: null,
          active: null,
        }
      },

      async getCategories() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''

          let typeString = ''

          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }

          if (this.filters.type && this.filters.type.name) {
            typeString = '&type=' + this.filters.type.id
          }

          queryParams = searchString + typeString
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'categories?page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.from
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              switch (x.type) {
                case '0':
                  x.typeText = 'Empresas'
                  break
                case '1':
                  x.typeText = 'Blog'
                  break
                case '2':
                  x.typeText = 'Eventos'
                  break
                case '3':
                  x.typeText = 'Noticias'
                  break
                case '4':
                  x.typeText = 'Glosario de Términos'
                  break
              }
            })
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'

        this.name = ''
        this.image = null

        this.type = ''

        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.type = this.item.type

        this.image = null

        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        let formData = new FormData()
        this.loading = true
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }
        if (this.image) {
          formData.append('file', this.image)
        }

        formData.append('fileType', 'image')
        formData.append('name', this.name)

        formData.append('type', this.type)

        axios
          .post(process.env.VUE_APP_API_DIRECTORY + 'categories', formData, this.config)
          .then((response) => {
            this.page = 1
            this.getCategories()
            this.$modal.hide('crudModal')
            this.loading = false
          })
      },

      async saveItem() {
        let formData = new FormData()

        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }
        if (this.image) {
          formData.append('file', this.image)
          if (this.item.image) {
            formData.append('fileId', this.item.image.id)
          }
        }
        formData.append('fileType', 'image')
        formData.append('_method', 'patch')
        formData.append('name', this.name)
        formData.append('type', this.type)

        axios
          .post(
            process.env.VUE_APP_API_DIRECTORY + 'categories/' + this.item.id,
            formData,
            this.config
          )
          .then((response) => {
            this.page = 1
            this.getCategories()
            this.$modal.hide('crudModal')
            this.loading = false
          })
      },

      async deleteItem(item) {
        this.loading = true
        axios
          .delete(process.env.VUE_APP_API_DIRECTORY + 'categories/' + item.id)
          .then((response) => {
            this.page = 1
            this.items = []
            this.getCategories()
          })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getCategories()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
  .ql-editor .ql-video {
    display: block;
    max-width: 100%;
    width: 70%;
    margin: auto;
    height: 300px;
  }
  .v-textarea textarea {
    height: 80px;
  }
  .text-box strong {
    font-weight: 600;
  }
</style>
